.details-container{
    display: flex;
    gap: 0 50px;
}
.details-card{
    width: 50%;
    min-width: 460px;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: hsl(284deg 66% 17%);
    padding: 20px;
    display: grid;
    /* grid-template-areas: "transaction date send"
                         "hash bank recive"
                         "network bank status"
                         "wallet user control"; */
    grid-template-areas: "transaction date send"
                         "hash pay recive"
                         "network through status"
                         /* "wallet user control" */
                         "banku banka banka"
                         "user control control";
    gap: 10px;
    font-family: 'Rubik', sans-serif;
    height: 350px;
    font-size: 12px;
}

.details-card-chat{
    width: 50%;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: hsl(284deg 66% 17%);
    padding: 20px;
    gap: 10px;
    font-family: 'Rubik', sans-serif;
    position: relative;
}

.details-item-transaction{
    grid-area: transaction;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}
.details-item-hash{
    grid-area: hash;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
    font-size: 9px;
    max-width: 120px;
}
.details-item-send{
    grid-area: send;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}
.details-item-recive{
    grid-area: recive;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}
.details-item-date{
    grid-area: date;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}
.details-item-network{
    grid-area: network;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}
/* .details-item-bank{
    grid-area: bank;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
} */


.details-item-pay{
    grid-area: pay;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}

.details-item-through{
    grid-area: through;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
    position: relative;
}

.details-item-method-recive{
    grid-area: methodRecive;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
    max-width: 120px;
}

.methodRecive{
    display: flex;
    flex-direction: column;
    word-break: break-all;
    max-width: 120px;
    font-size: 12px;
}

.details-item-bank-u{
    grid-area: banku;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}

.details-item-bank-a{
    grid-area: banka;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}

.details-item-status{
    grid-area: status;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
}

/* .details-item-wallet{
    grid-area: wallet;
    display: flex;
    flex-direction: column;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    word-break: break-all;
    font-size: 10px;
} */

.details-item-user{
    grid-area: user;
    display: flex;
    flex-direction: column;
    /* max-height: 40px;
    min-height: 40px;
    height: 40px; */
    word-break: break-all;
    font-size: 10px;
    padding-top: 10px;
    margin-top: 20px;
}
.details-item-control{
    grid-area: control;
    display: flex;
    flex-direction: column;
    /* max-height: 40px;
    min-height: 40px;
    height: 40px; */
    word-break: break-all;
    padding-top: 10px;
    margin-top: 20px;
}

.item-title{
    color: #ccc;
    font-size: 10px;
}

.card-bank-container{
 
}

.bank-card{
    display: grid;
    /* gap: 3px 0; */
    position: relative;
}

.bank-card .cardBank{
    display: grid;
    grid-template-areas: "img contenido contenido"
                         "img footer footer";
    box-shadow: 0 0 6px 1px rgb(145 150 163 / 28%);
    border-radius: 10px;
    padding: 4px;

    font-size: 10px;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    width: 100%;
    min-width: 125px;
}

.card-img{
    grid-area: img;
    box-shadow: 0 0 6px 1px rgb(145 150 163 / 50%);
    max-width: 30px;
    max-height: 30px;
}

.card-img img{
    width: 100%;
    max-width: 30px;
    max-height: 30px;
    padding: 2px !important;
} 

.card-title{
    grid-area: title;
    font-size: 14px;
}
.card-content{
    grid-area: contenido;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    position: relative;
    /* top: -12px; */
    font-size: 8px;
}
.card-footer{
    grid-area: footer;
    display: flex;
    padding: 0 !important;
    gap: 5px;
}

.chat-from{
    
}

.chat-from input{
    background-color: rgb(49 13 62);
    border: none;
    padding: 5px;
    color: white;
    width: 75%;
}

.chat-from button{
    border-radius: 0 20px 20px 0;
    background-color: #ecb80a;
    color: #290e36;
    width: 25%;
}

.chat-title{
    display: flex;
    align-items: center;
    gap: 0 5px;
    padding: 10px 0;
}

.chat-online{
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #2ecc71;
}

.chat-close{
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: tomato;
}

.chat-content{
    height: 220px;
   display: grid;
   place-items: center;
}

.chat-content-item{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 5px
}
.chat-content-item-admin{
    display: flex;
    display:-webkit-flex; 
    flex-direction: row-reverse;
    flex-direction:-webkit-row-reverse; 
    justify-content: left;
    justify-content:-webkit-left; 
    align-items: baseline;
    align-items: -webkit-baseline;
    gap: 5px;
}

.chat-content-time{
    font-size: 9px;
    min-width: 32px;
}

.chat-active{
    height: 220px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    flex-direction: -webkit-column;
    padding: 15px;
    background-color: #46185b;
    margin: 0 0 10px 0;
    border-radius: 8px;
    overflow-y: auto;
 }

 .chat-content-p{
    display: flex;
    display: -webkit-flex;
    float: right;
    background-color: #390f48;
    padding: 5px;
    border-radius: 10px;
 }

 .chat-content-admin{
    display: flex;
    display: -webkit-flex;
    float: left;
    background-color: #ecb80a;
    padding: 5px;
    border-radius: 10px;
    color: #0a071d;
 }

.chat-icon{
    font-size: 60px;
    color: rgba(204, 204, 204, 0.366);
}

.chat-content p{
    color: #ccc;
    font-weight: 100;
    font-size: 14px;
    
 }

.chat{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    flex-direction: -webkit-column;
    justify-content: space-between;
    justify-content: -webkit-space-between;
}

@media (max-width: 991px) {

    .details-container{
        flex-direction: column;
        flex-direction: -webkit-column;
        gap: 5px 0;
    }

    .details-card, .details-card-chat{
        width: 100%;
        min-width: auto;
    }
    
    .details-card{
        display: grid;

        /* "transaction date send"
                         "hash pay recive"
                         "network through status"
                         /* "wallet user control" */
                         /*"banku banka banka"
                         "user control control"; */
        grid-template-areas: "transaction date"
                             "send hash"
                             "pay recive"
                             "through status"
                             "network banku"
                             "banka user"
                             "control control" !important;
        font-size: 12px;
        gap: 40px 30px;
        height: auto;
        padding-bottom: 40px;
    }
    .bank-card{
        padding-bottom: 50px;
        /* gap: 25px 0 0 0; */
    }
    
}

@media (max-width: 500px) {
    .details-card{
        grid-template-areas: "transaction" 
                             "date"
                             "send"
                             "hash"
                             "pay"
                             "through"
                             "recive"
                             "network"
                             "banku"
                             "banka"
                             /* "wallet" */
                             "user" 
                             "control"
                             "status !important";
        gap: 15px 0;
        padding-bottom: 50px;
    }
    .bank-card{
        /* padding-bottom: 0px;
        margin-bottom: 20px; */
        /* height: auto; */
    }
    .details-item-recive{
        /* margin-top: 40px; */
    }
    .chat-from input{
        width: 70%;
    }
    .chat-from button{
        width: 30%;
    }
}