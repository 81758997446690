@import url('./root.module.css');

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 100px;
    /* background-color: #0a071d; */
    /* background-image: 
        var(--darkgradientPurple), 
        var(--darkgradientPurple2); */
    height: 100vh;
    overflow: hidden;
    transition: all 0.3s ease;
}

.headerLight{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 100px;
    /* background: rgb(118,197,255);
    background: var(--lightGradientWhite); */
    height: 100vh;
    overflow: hidden;
    transition: all 0.3s ease;
}

.headerTitle{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerTitleLight{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerTitle h1{
    font-size: 3.6vw;
    font-weight: 500;
    color: #fff;
    text-align: center;
}

.headerTitleLight h1{
    font-size: 3.6vw;
    font-weight: 500;
    color: #1c0942;
    text-align: center;
}

.headerOverline{
    position: relative;
}

.headerOverline::before{
    content: "";
    position: absolute;
    bottom: 5px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.09);
    height: 2px;
}

.headerOverline::after{
    content: "";
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.09);
    height: 2px;
    top: 5px;
    left: 0;
}

.headerTextSpan{
    font-size: 6vw;
    display: inline-block;
    position: relative;
    z-index: 1;
    color: #fff;
}

.headerTextSpan::before{
    /* content: "";
    position: relative;
    width: 600px;
    height: 2px; */
    /* position: relative; */
    /* display: inline-block; */
   
    content: "";
    position: absolute;
    width: 100%;
    background: rgb(37,22,125);
    background: var(--gradientPurple);
    height: 100%;
    top: 5px;
    left: 0;
    z-index: -1;
}

.headerParrafo{
    margin-top: 30px;
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.417);
    font-weight: 300;
}

.headerParrafoLight{
    margin-top: 30px;
    font-size: 1.2vw;
    color: #1c0942;
    font-weight: 300;
}

.headerPicture{
    width: 50%;
    display: flex;
    justify-content: center;
}

.metamask{
    background-color: #57235d !important;
}

.picture{
    width: 100%;
    max-width: 500px;
    -webkit-animation: mover 1.5s infinite alternate;
    animation: mover 1.5s infinite alternate;
    filter: drop-shadow(0 4px 10px rgba(26, 6, 52, 0.872));
}   

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-7px);
    }
  }
  @keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-7px);
    }
  }
  

.btnTrade{
    border: 2px solid #57235d;
    padding: 12px 10px;
    margin-left: 10px;
    color: #fff;
    background-color: #1d122f;
    border-radius: 20px;
}

.btnTrade:hover{
    color: #fff;
}

.btnDetails{
    border: 2px solid #57235d;
    padding: 12px 15px;
    margin-left: 10px;
    color: #fff;
    background: rgb(37,22,125);
    background: linear-gradient(311deg, rgba(37,22,125,1) 0%, rgba(111,16,107,1) 82%);
    border-radius: 20px;
}

.btnDetails:hover{
    color: #fff;
}

.containerMap{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card{
    width: 100%;
    max-width: 450px;
    padding: 20px;
    color: #fff;
    text-align: center;
    position: relative;
}
.cardLight{
    width: 100%;
    max-width: 450px;
    padding: 20px;
    color: #1c0942;
    text-align: center;
    position: relative;
}

.cardHeader{
    display: flex;
    justify-content: center;
}

.cardNum{
    display: grid;
    place-items: center;
    font-size: 38px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #57235d;
    position: absolute;
}

.cardTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.cartContent{
    font-weight: 100 !important;
}

.arrow{
    font-size: 130px;
    color: #fff;
    text-align: center;
    width: 100%;
}
.arrowLight{
    font-size: 130px;
    text-align: center;
    width: 100%;
    color: #1c0942;
}

.call{
    position: relative;
    top: 100px !important;
    padding: 40px 0 !important;
}

.paragraph{
    color: rgba(255, 255, 255, 0.417);
}

.paragraph:hover{
    color: rgba(255, 255, 255, 0.699);
}

.paragraphLight{
    color: #1c0942;
}

.paragraphLight:hover{
    color: #4d3977;
}

@media (max-width: 992px) { 

    .header, .headerLight{
        margin-top: -120px;
        padding-top: 100px;
    }

    .picture{
        margin-left: 50px;
    }

 }

@media (max-width: 576px) { 
    .header, .headerLight{
        padding: 0px 10px;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 40px 0;
        height: 100vh;
        top: -40px;
        margin-bottom: 0px;
        margin-top: -130px;
    }

    .headerTitle, .headerTitleLight{
        width: 80%;
    }

    .headerParrafo, .headerParrafoLight{
        font-size: 1rem;
        margin-top: -20px;
        line-height: 18px;
    }
    
    .headerTitle h1, .headerTitleLight h1{
        font-size: 1.5rem;
    }

    .headerTextSpan::before{
        top: 0px;
    }

    .headerPicture{
        margin-top: 20px;
    }

    .btnDetails, .btnTrade{
        margin-left: 0px;
        padding: 10px 5px;
        margin-right: 2px;
        margin-top: -12px;
    }

    .picture{
        margin-left: 0px;
        max-width: 160px;
    }
 }

