.details-container{
    /* display: flex; */
    /* gap: 0 10px; */
}
.details-card-user{
    /* width: 50%;
    min-width: 460px;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: hsl(284deg 66% 17%);
    padding: 20px;
    display: grid;
    grid-template-areas: "transaction date send"
                         "hash pay recive"
                         "network through status"
                         "banku banka banka";
    gap: 10px;
    font-family: 'Rubik', sans-serif;
    height: 350px;
    font-size: 1.1vw; */
    position: relative;
}

.details-card-chat{
    /* width: 50%;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: hsl(284deg 66% 17%);
    padding: 20px;
    gap: 10px;
    font-family: 'Rubik', sans-serif; */
    /* position: relative; */
}

.details-item-transaction{
    /* grid-area: transaction;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all; */
}
.details-item-hash{
    /* grid-area: hash;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
    font-size: 9px; */
}
/* .details-item-send{
    grid-area: send;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
}
.details-item-recive{
    grid-area: recive;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
}
.details-item-date{
    grid-area: date;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
}
.details-item-network{
    grid-area: network;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
}
.details-item-bank{
    grid-area: bank;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
}
.details-item-status{
    grid-area: status;
    display: flex;
    flex-direction: column;
    height: 50px;
    word-break: break-all;
} */

.item-title{
    color: #ccc;
    font-size: 10px;
}

.card-bank-container{
 
}

.bank-card{
    display: grid;
    gap: 10px 0;
}

.bank-card .cardBank{
    /* display: grid;
    grid-template-areas: "img title footer"
                         "img contenido footer";
    box-shadow: 0 0 6px 1px rgb(145 150 163 / 28%);
    border-radius: 10px;
    padding: 5px;
    width: 210px;
    height: 50px;
    font-size: 10px;
    justify-content: space-evenly; */
}

/* .card-img{
    grid-area: img;
    box-shadow: 0 0 6px 1px rgb(145 150 163 / 50%);
    max-width: 50px;
    height: 40px;
} */
/* 
.card-img img{
    width: 100%;
    max-width: 40px;
    padding: 5px;
} 

.card-title{
    grid-area: title;
    font-size: 14px;
}
.card-content{
    grid-area: contenido;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    top: -12px;
    font-size: 8px;
}
.card-footer{
    grid-area: footer;
    display: flex;
    flex-direction: column;
}

.chat-from{
    
}

.chat-from input{
    background-color: rgb(49 13 62);
    border: none;
    padding: 5px;
    color: white;
    width: 80%;
}

.chat-from button{
    border-radius: 0 20px 20px 0;
    background-color: #ecb80a;
    color: #290e36;
}

.chat-title{
    display: flex;
    align-items: center;
    gap: 0 5px;
    padding: 10px 0;
}

.chat-online{
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #2ecc71;
}

.chat-content{
   height: 150px;
   display: grid;
   place-items: center;
}

.chat-content-item{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 5px
}
.chat-content-item-admin{
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: baseline;
    gap: 5px;
}

.chat-content-time{
    font-size: 9px;
    min-width: 32px;
}

.chat-active{
    height: 150px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #46185b;
    margin: 0 0 10px 0;
    border-radius: 8px;
    overflow-y: auto;
 }

 .chat-content-p{
    display: flex;
    float: right;
    background-color: #390f48;
    padding: 5px;
    border-radius: 10px;
 }

 .chat-content-admin{
    display: flex;
    float: left;
    background-color: #ecb80a;
    padding: 5px;
    border-radius: 10px;
    color: #0a071d;
 }

.chat-icon{
    font-size: 60px;
    color: rgba(204, 204, 204, 0.366);
}

.chat-content p{
    color: #ccc;
    font-weight: 100;
    font-size: 14px;
    
 }

.chat{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.arrow-content{
    position: absolute;
    right: 60px;
    font-size: 20px;
    top: 25px;
    cursor: pointer;
}

.arrow-content:hover{
    color: rgb(223, 203, 55);
}

.arrow-content-open {
    position: absolute;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    bottom: 0;
}

.arrow-content-open:hover{
    color: rgb(223, 203, 55);
}

@media (max-width: 991px) {
    .chat-hidden{
        display: none !important;
    }
}