@import url('./root.module.css');

.details{
    overflow: hidden;
    background: rgb(26,10,57);
    background: var(--darkgradientPurpleLineal);
}

.detailsLight{
    overflow: hidden;
    background: rgb(118,197,255);
    background: var(--lightGradientWhite);
}

.textDetails{
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleDetails{
    font-size: 2vw;
    display: inline-block;
    position: relative;
    z-index: 1;
    color: #fff;
}

.titleDetails::before{   
    content: "";
    position: absolute;
    width: 100%;
    background: rgb(37,22,125);
    background: linear-gradient(311deg, rgba(37,22,125,1) 0%, rgba(111,16,107,1) 82%);
    height: 100%;
    left: 0;
    z-index: -1;
}

.subTitleDetails{
    font-size: 2.5vw;
    display: inline-block;
    position: relative;
    z-index: 1;
    color: #fff;
    font-weight: 500;
}

.subTitleDetailsLight{
    font-size: 2.5vw;
    display: inline-block;
    position: relative;
    z-index: 1;
    color: #1c0942;
    font-weight: 500;
}

.spanTitleDetails{
    font-weight: 200;
}

.paTitleDetails{
    font-size: 1.2vw;
    width: 80%;
    text-align: center;
    color: rgba(255, 255, 255, 0.417);
}

.paTitleDetailsLight{
    font-size: 1.2vw;
    width: 80%;
    text-align: center;
    color: #1c0942;
}

.containerCard{
    padding-top: 50px;
    height: 50vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 0 50px;
}

.cardIcon{
    margin: 30px;
    margin-top: -65px;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    font-size: 25px;
    border-radius: 20px;
    border: 3px solid #57235d;
    background: rgb(26,10,57);
    background: linear-gradient(311deg, rgba(26,10,57,1) 0%, rgba(57,11,62,1) 54%, rgba(26,10,57,1) 100%);
}

.titleCard{
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.716);
    padding: 50px 0;
    padding-top: 30px;
}

.titleCardLight{
    font-size: 1.2vw;
    color: #1c0942;
    padding: 50px 0;
    padding-top: 30px;
}

@media (max-width: 991.98px) {
    .titleCard, .titleCardLight{
        font-size: 2vw;
    }
}

@media (max-width: 767.98px) {
    .titleDetails{
        text-align: center;
        font-size: 1.5rem;
    }
    .subTitleDetails, .subTitleDetailsLight{
        text-align: center;
        margin: 0 10px;
        font-size: 1.2rem;
    }
    .paTitleDetails, .paTitleDetailsLight{
        font-size: 1rem;
        padding: 0 25px;
    }
    .details, .detailsLight{
        gap: 2px;
    }
    .containerCard{
        height: auto;
        flex-direction: column;
        gap: 70px 0;
        align-items: center;
        padding-top: 0;
        justify-content: space-around;
        padding-bottom: 90px;
    }
    .titleCard, .titleCardLight{
        font-size: 1.1rem;
    }

    .cardIcon{
        top: -65px;
    }

    .textDetails{
        height: 60vh;
    }
}
