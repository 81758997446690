.franja{
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    color: rgba(255, 255, 255, 0.417);
    font-size: 25px;
    padding-top: 10px;
    background-color: #0a071d;
}

.franjaLight{
    background-color: rgb(118,197,255);
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    color: #1c0942a3;
    font-size: 25px;
    padding-top: 10px;
}


@media (max-width: 576px) {
    .franja, .franjaLight{
        display: none;
    }
}