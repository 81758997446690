.container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-evenly;
    height: 95vh;
    color: #ccc;
  }
  
  .image {
    margin-right: 20px; /* Espacio entre la imagen y el formulario */
  }
  
  .image img {
    max-width: 300px;
    border-radius: 10px;
  }

  .title{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    color: white;
    height: 80px;
    /* position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 140px; */
  }

  .titleLight {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    color: #1c0942;
    height: 80px;
    /* position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 140px; */
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alineamos el formulario a la izquierda */
    width: 540px;
  }

  .formContainer {
    width: 100%;
    min-height: auto;
    border-radius: 20px;
    background-color: var(--darkBlue);
    padding: 20px;
    color: aliceblue;
  }
  
  .form h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    border-radius: 15px;
    border: none !important;
    background-color: rgba(0, 0, 0, 0.253);
    color: aliceblue;
    padding: 0px 20px 0px 0px;
    text-align: end;
    height: 50px;
  }
  
  .btn {
    padding: 10px 15px;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 15px;
    border: none;
    background-color: var(--yellow);
    color: var(--darkBlue);
    padding: 0px 20px 0px 0px;
    height: 50px;
    font-weight: 500;
  }

  .register {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .registerLight {
    width: 100%;
    text-align: center;
    padding: 10px;
    color: #1c0942;
  }

  .linkRegister{
    color: var(--yellow);
  }

  .linkRegister:hover{
    color: var(--yellow);
  }

  .linkRegisterLight{
    color: #1c0942;
    font-weight: 500;
  }

  .linkRegisterLight:hover{
     color: #1c0942;
  }

  .login{
    display: flex;
    justify-content: space-between;
    /* width: 70px; */
    padding: 4px 4px;
    background-color: #3a1e44;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 2px 3px 6px 1px rgb(0 0 0 / 12%);
    color: rgb(223, 203, 55) !important;
    text-align: center;
    font-weight: 400 !important;
    font-size: 16px !important;
}

  .flexDirection{
    flex-direction: row-reverse;
  }
  
  /* Estilos para hacer el formulario responsive */
  @media (max-width: 768px) {

    .title{
      position: relative;
      top: 0;
    }

    .container {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      /* margin-top: 50px; */
    }
  
    .image {
      margin-right: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }

    .image img {
      width: 20%;
      display: flex;
      align-items: center;
    }
  
    .form {
      width: 80%;
      align-items: center;
    }
  
    .formGroup input {
      width: 100%;
    }
  }
  