@import url('./root.module.css');
.secureContainer{
    background: rgb(26,10,57);
    background: var(--darkgradientPurpleLineal);
}

.secureContainerLight{
    background: rgb(118,197,255);
    background: var(--lightGradientWhite);
}

.secure{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    
}

.secureLight{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
}


.container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 250px;
}

.text{
    width: 50%;
    text-align: left;
    max-width: 400px;
}

.textTitle{
    font-size: 3vw;
    color: #fff;
}

.textTitleLight{
    font-size: 3vw;
    color: #1c0942;
}

.textParagraph{
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.417);
}

.textParagraphLight{
    font-size: 1.2vw;
    color: #1c0942;
}

.link{
    color: #1c0942 !important;
}

.link:hover{
    color: #533b84 !important;
}

.linkDark:hover{
    color: #533b84 !important;
}

.picture{
    width: 50%;
}

.pictureImg{
    position: relative;
    top: -60px;
    width: 100%;
    max-width: 500px;
}



@media (max-width: 576px) {

    .picture{
        margin-top: -100px;
    }

    .secure, .secureLight{
        flex-direction: column;
    }

    .container{
        width: 100%;
        height: 80vh;
        margin: 0 auto;
        flex-direction: column-reverse;
    }

    .picture{
        width: 100%;
    }

    .textTitle, .textTitleLight{
        font-size: 1.2rem;
    }

    .textParagraph, .textParagraphLight{
        font-size: 1rem;
    }

    .text{
        width: 100%;
    }

}

@media (max-width: 360px) {
    .picture{
        margin-top: 0px;
    }
}