.transactionItem{
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: pointer;
}

.transactionItem:hover{
    border: 1px solid #da42f8;
}

.transactions-container{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
}

.textLight{
    color: #290e36;
}

@media screen and (max-width: 991px) {
    .transactions-container{
        display:inline-block;
    }
    .transactions-item{
        align-items: center;
        padding-bottom: 20px;
    }
  }

.transactions-item{
    display: flex;
    flex-direction: column;
    place-content: center;
    transition: all 2s;
}

.transaction-icon{
    
}

.transaction-icon i{
    place-content: center;
    font-size: 25px;
    display: flex;
    place-content: center;
}

.transaction-status-warnign{
    color: #ecb80a;
}
.transaction-status-danger{
    color: #eb4d4b;
}
.transaction-status-success{
    color: #1dd1a1;
}

.modalContent{
    display: flex;
    flex-direction: column;
    gap: 10px 0;
}

.date-container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.modalContent button, .modalContent input{
    border-radius: 8px;
    padding: 8px;
    background-color: #290e36;
    color:white;
    font-family: 'Rubik', sans-serif;
    border: 1px solid transparent;
    justify-self: flex-end;
}

.modalContent button:hover, .modalContent input:hover{
    border: 1px solid #da42f8;
}

.modalContent .btn-filter{
    background-color: #ecb80a;
    color: #290e36;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
}

.btn-active{
    background-color: #421e53 !important;
}