@import url('./root.module.css');

.footer{
    display: flex;
    justify-content: space-evenly;
    padding: 50px;
    overflow: hidden;
    background: rgb(26,10,57);
    background: var(--darkgradientPurpleLineal);
    padding-bottom: 150px;
}

.footerLight{
    display: flex;
    justify-content: space-evenly;
    padding: 50px;
    overflow: hidden;
    background: rgb(118,197,255);
    background: var(--lightGradientWhite);
    padding-bottom: 150px;
}

.logo{
    width: 300px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    /* justify-content: space-around; */
}

.source{
    width: 300px;
}

.about{
    width: 300px;
}

.social{
    display: flex;
    gap: 0 10px;
}

.imgSocial{
    width: 100%;
    max-width: 40px;
}

.title{
    color: #fff;
}

.titleLight{
    color: #1c0942;
}

.paragraph{
    color: rgba(255, 255, 255, 0.417);
}

.paragraph:hover{
    color: rgba(255, 255, 255, 0.699);
}

.paragraphLight{
    color: #1c0942;
}

.paragraphLight:hover{
    color: #4d3977;
}

.inputNewLetter{
    padding: 13px;
    border-radius: 20px 0 0 20px;
    background-color: #1d122f;
    border: 2px solid #57235d;
    color: #fff;
    font-weight: 400;
}

.buttonNewLetter{
    padding: 14px;
    border-radius: 0 20px 20px 0;
    border: 2px solid #57235d;
    color: #fff;
    background: rgb(37,22,125);
    background: linear-gradient(311deg, rgba(37,22,125,1) 0%, rgba(111,16,107,1) 82%);
    font-weight: 500;
}
.footerLogo{
    display: flex;
    flex-direction: column;
}

.ruc{
    font-weight: 500;
    color: rgba(255, 255, 255, 0.417);
    font-size: 0.5em;
}

.rucLigth{
    font-weight: 500;
    color: #411b51;
    font-size: 0.5em;
}

.copy{
    font-weight: 400;
    color: rgba(255, 255, 255, 0.417);
    text-align: center;
    margin-top: -70px;
}

.copyLigth{
    font-weight: 400;
    color: #1c0942;
    text-align: center;
    margin-top: -70px;
}

.aboutItem{
    display: flex;
    gap: 0 5px;
    color: rgba(255, 255, 255, 0.417);
}

.aboutItemLigth{
    display: flex;
    gap: 0 5px;
    color: #1c0942;
}

.link{
    color: rgba(255, 255, 255, 0.417);
}
.linkLigth{
    color: #1c0942;
}

@media (max-width: 1345px) {
    .inputNewLetter{
        width: 160px;
    }

    .logo, .source, .about{
        width: 200px;
    }
    
}

@media (max-width: 1200px) {
    .logo, .source, .about{
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .footer, .footerLight{
        flex-direction: column;
        gap: 40px 0;
        /* margin-bottom: 100px; */
    }
    .inputNewLetter{
        width: 130px;
    }

    
}

@media (max-width: 360px) {
    .inputNewLetter{
        width: 120px;
    }
    .copy{
        margin-top: -80px;
    }
}

