:root{
    --darkBlue:rgb(13,32,44);
    --darkBlue2:rgb(18,44,59);
    --yellow:rgb(236,184,10);
    --yellow2:rgba(187, 146, 12, 0.733);
    --lightBlue:rgb(118, 146, 168);
    --white:aliceblue;
}
.header1{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 40px;
}
.header1In{
    width: 100%;
    padding: 20px;
}
.header1 h1{
    font-size: 60px;
    color:var(--yellow);
}
.header1 p{
    font-size: 20px;
    font-weight: bold;
    color: aliceblue;
}
.navLinkbtn{
    display: flex;
    text-decoration: none;
    color: var(--darkBlue);
}

.button1{
    margin-right: 10px;
    height: 40px;
    font-weight: 600;
    background-color: var(--yellow);
    padding: 3px 25px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button1:hover{
    cursor: pointer;
}
.button1:active{
    transform: translateY(2px);
}

.button2{
    margin-right: 10px;
    height: 40px;
    font-weight: 600;
    background-color: var(--white);
    padding: 3px 25px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button2:hover{
    cursor: pointer;
}
.button2:active{
    transform: translateY(2px);
}

.header2{
    padding: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header2 img{
    height: 90%;
}

.login{
    display: flex;
    justify-content: space-between;
    /* width: 70px; */
    padding: 4px 4px;
    background-color: #3a1e44;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 2px 3px 6px 1px rgb(0 0 0 / 12%);
    color: rgb(236,184,10) !important;
    text-align: center;
}