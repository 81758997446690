* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
}

/* :root{
    --darkBlue:rgb(13,32,44);
    --darkBlue2:rgb(18,44,59);
    --yellow:rgb(236,184,10);
    --yellow2:rgba(187, 146, 12, 0.733);
    --lightBlue:rgb(118, 146, 168);
    --white:aliceblue;
    --lightModa: #ecf7ff;
    --lightNav: #ecf6ff;
} */


:root {
    --darkBlue: hsl(284.75, 66%, 17%);
    --darkBlue2: hsl(284.75, 66%, 17%);
    --yellow: rgb(223, 203, 55);
    --yellow2: rgba(187, 146, 12, 0.733);
    --lightBlue: rgba(124, 80, 175, 0.685);
    --light: rgba(124, 80, 175, 0.685);
    --lightBlue2: rgba(175, 221, 255, 1);
    --white: aliceblue;
    --lightModa: #ecf7ff;
    --lightNav: #ecf6ff;
    --success: rgb(40, 156, 66);
    --pink: rgb(220, 173, 235);
    --darkGradient: linear-gradient(31deg, rgba(26, 10, 57, 1) 0%, rgba(43, 9, 47, 1) 54%, rgba(26, 10, 57, 1) 100%);
    --lightGradientWhite: linear-gradient(311deg, rgb(118, 197, 255) 0%, rgb(208, 235, 255) 50%, rgb(175, 221, 255) 100%);
    --alertText: rgb(110, 4, 100);
    --alertBackground: rgba(194, 67, 189, 0.878);
    --selected: var(--yellow);
}

body {
    margin: 0px;
    padding: 0px;
}

.bgDarkFondo {
    background: var(--darkGradient) !important;
}

.bgLightFondo {
    background: var(--lightGradientWhite) !important;
}

/* body{
    background-image: 
        radial-gradient(at 47% 33%, hsl(284.75, 66%, 17%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(284.75, 66%, 17%) 0, transparent 55%);
} */

/* width */
::-webkit-scrollbar {
    width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #422251;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1a0d1f;
    cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #26142ed9;
}

.yellow {
    color: var(--yellow);
}

.nav-section-2 .active {
    color: var(--yellow) !important;
}

.principal-nav {
    /* background-color: var(--darkBlue); */
    margin-top: 8px;
    font-weight: 400;
    /* transition: all 0.3s ease; */
    z-index: 10;
}

.principal-nav-light {
    /* background-color: var(--lightNav); */
    margin-top: 8px;
    font-weight: 400;
    /* transition: all 0.3s ease; */

}

.container-nav {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin: 0 10px;
    padding-top: 0px;
}

.nav-section-1 {
    color: var(--white);
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}

.nav-section-1:hover {
    color: var(--white);
}

.nav-footer-logo {
    display: flex;
    flex-direction: column;
    color: var(--white);
}

.nav-footer-logo img {
    height: 40px;
    color: aliceblue;
    margin-right: 8px;
}

.nav-text-logo {
    color: var(--darkBlue2) !important;
}

.nav-section-1 img {
    height: 35px;
    color: aliceblue;
    margin-right: 8px;
}

.nav-link {
    color: var(--lightBlue);
    text-decoration: none;
}

.nav-linkLight {
    color: rgb(122, 110, 170);
    text-decoration: none;
}

.nav-link:hover {
    color: var(--white);
}

.nav-section-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lightBlue);
    z-index: 1;
    margin-left: 15px;
}
.nav-section-2-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lightBlue);
    z-index: 1;
    margin-left: 15px;
    gap: 20px;
    font-size: 16px;
}

.nav-section-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lightBlue);
    z-index: 1;
}

.connect-btn {
    background-color: var(--yellow);
    border: none;
    border-radius: 20px;
    text-align: end;
    width: 170px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 3px 10px;
    position: relative;
}

.external-circle {
    position: absolute;
    left: -1px;
    background-color: var(--yellow);
    border-radius: 50%;
    padding: 1px;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.internal-circle {
    background-color: #3a1e44;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    padding: 3px;
}

.walletimg {
    height: 70%;
}

.container-fluid {
    padding: 0px !important;
}

.homeSection1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 90vh;
}

.swapSection {
    min-height: 90vh;
    color: aliceblue;
}

.bg-b {
    background-color: var(--darkBlue2);
}

button {
    border: none;
    border-radius: 20px;
    justify-content: end;
    align-items: center;
    padding: 3px 10px;
    position: relative;
}

.pad {
    padding: 60px 20px;
    margin-bottom: -100px !important
}

.modal-wrapper {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}

.modal-display {
    background-color: var(--darkBlue);
    min-height: 30vw;
    max-height: 90vh;
    min-width: 30vw;
    max-width: 90vw;
    color: aliceblue;
    border-radius: 15px;
    position: absolute;
    overflow-y: scroll;
    z-index: 9999 !important;
}


.modal-display::-webkit-scrollbar {
    width: 0px;
}

.x {
    font-size: 24px;
    color: var(--yellow);
}

.x:hover {
    cursor: pointer;
    color: var(--yellow2);
}

.modalHeader {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--darkBlue2);
    padding-bottom: 10px;
}

.modalContent img {
    height: 25px;
}

.coinList {
    padding: 20px;
    display: flex;
    align-items: center;
    transition: 0.1s;
    cursor: pointer;
}

.coinList:hover {
    background-color: rgba(0, 0, 0, 0.397);
}

.coinListInactive {
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: rgba(58, 58, 58, 0.333);
}

.coinList h4 {
    color: var(--lightBlue);
    font-size: 12px;
    padding: 0px;
    margin: 0px;
}

.coinListInactive p {
    color: gray;
    font-size: 18px;
    padding: 0px;
    margin: 0px;
}

.coinListInactive h4 {
    color: gray;
    font-size: 12px;
    padding: 0px;
    margin: 0px;
}

.coinList p {
    color: aliceblue;
    font-size: 18px;
    padding: 0px;
    margin: 0px;
}

.bgtransparentDark {
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 99;
}

.spinner-border {
    width: 60px;
    height: 60px;
}

.bankModalBody {
    background-color: var(--darkBlue);
    min-height: 30vw;
    max-height: 90vh;
    min-width: 30vw;
    max-width: 90vw;
    color: aliceblue;
    border-radius: 15px;
    position: absolute;
    overflow-y: scroll;
}

.bankModalBody::-webkit-scrollbar {
    width: 0px;
}

.backOptions {
    padding: 20px;
    text-align: left;
}

.backOptions img {
    height: 20px;
}

.backOptions span {
    color: var(--lightBlue);
    font-size: 14px;
}

ul {
    list-style: none;
}

a {
    color: aliceblue;
    text-decoration: none;
}

.adminHeader {
    padding: 0px;
}

.adminHeaderLight {
    padding: 0px;
}

.adminHeader h4 {
    color: aliceblue;
    padding: 0px;
    margin: 0px;
}

.adminHeaderLight h4 {
    color: #1c0942;
    padding: 0px;
    margin: 0px;
}

.adminHeader p {
    color: var(--lightBlue);
}

.adminHeaderLight p {
    color: #1c0942;
}

/*<---------------------------->*/

.dashHeader {
    margin-top: 0px;
    padding: 0px 0px 0px 0px;
}

.dashHeaderLight {
    padding: 10px 0px 0px 0px;
}

.dashHeader h3 {
    color: aliceblue;
    padding: 0px;
    margin: 0px;
}

.dashHeaderLight h3 {
    color: #1c0942;
    padding: 0px;
    margin: 0px;
}

.dashHeader p {
    color: var(--lightBlue);
}

.dashHeaderLight p {
    color: #1c0942;
}

.adminMenu {
    padding: 10px 20px;
    color: var(--lightBlue);
    user-select: none;
}



.adminMenu:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border-radius: 10px;
    color: aliceblue;
}

.adminMenuLight {
    padding: 10px 20px;
    color: #1c0942;
    user-select: none;
}

.adminMenuLight:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border-radius: 10px;
    color: aliceblue;
}

.sideMenu {
    border-right: 1px solid var(--darkBlue);
    min-height: 400px;
    padding: 10px;
}

ul {
    padding: 0px;
}

.changes {
    border-bottom: 1px solid var(--darkBlue);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.changesLight {
    border-bottom: 1px solid var(--darkBlue);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #1c0942;
}

.changes input {
    width: 100px;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.247);
    color: aliceblue;
    text-align: center;
    font-size: 24px;
}

.changesLight input {
    width: 100px;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.247);
    color: #1c0942;
    text-align: center;
    font-size: 24px;
}

.changes button {
    background-color: var(--yellow);
    padding: 8px;
    width: 80px;
}

.changesLight button {
    background-color: var(--yellow);
    padding: 8px;
    width: 80px;
}

.adminPercent {
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.adminPercentLight {
    align-items: center;
    justify-content: space-between;
    display: flex;
    color: #1c0942;
}

.adminPercent div {
    font-size: 24px;
    margin: 0px 30px;
}

.deleteButton {
    cursor: pointer;
}

.deleteButton:hover {
    color: var(--lightBlue);
}

.adminUserWallet {
    color: var(--lightBlue);
    font-size: 13px;
    border-bottom: 1px solid var(--darkBlue);
    padding-bottom: 5px;
}

.mb-1 {
    padding-bottom: 20px;
}

.lb {
    color: white;
    font-size: 14px;
}

.saveChanges {
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.saveChanges button {
    background-color: var(--yellow);
    padding: 10px 20px;
}

.usuariosModalBody {
    padding: 20px;
    color: aliceblue;
}

.dobleArrowWrap {
    display: flex;
    justify-content: center;
}

.dobleArrowCircle {
    background-color: rgba(0, 0, 0, 0.25);
    width: 35px !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
}

.dobleArrowCircle:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
}

.dobleArrowCircle:active {
    background-color: rgb(8, 60, 73);
    transform: scale(0.9);
}

.selectSwapItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.btnDisabled {
    background-color: rgba(128, 128, 128, 0.164) !important;
    color: rgba(126, 103, 126, 0.658) !important;
}

.bankItem {
    padding: 10px 20px;
    display: flex;
    line-height: 14px;
    align-items: center
}

.bankItem img {
    height: 50px;
    width: 50px;
    margin-right: 10px;
}

.bankItem:hover {
    background-color: #1a0d1f;
    cursor: pointer;
}

.bankImgModal {
    height: 40px !important;
    padding-right: 10px;
}

.secondaryText {
    font-size: 12px;
    color: rgba(177, 177, 177, 0.795);
    padding: 0px;
    margin: 0px;
    margin-top: 3px;
}

.saveBtn {
    width: 100%;
    background-color: var(--yellow);
    margin-top: 20px;
    height: 40px;
}

.saveBtnDisabled {
    width: 100%;
    background-color: rgba(170, 170, 170, 0.726);
    margin-top: 20px;
    height: 40px;
}

.bgDark {
    min-height: 300px;
    border-radius: 20px;
    background-color: var(--darkBlue);
    padding: 20px;
    color: aliceblue;
    margin-top: 20px;
}

.datosList {
    background-color: rgba(26, 9, 37);
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid rgba(86, 122, 133, 0.363);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 10px;
}

.datosList button {
    padding: 5px 20px;
}

.bancos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bList {
    border-bottom: 1px solid rgba(123, 86, 133, 0.363);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid hsl(285deg 61% 23%);
    background-color: #290e36;
    border-radius: 25px;
    font-size: 16px;
    min-width: 345px;
}

.bListTransaction {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/***/

.bListTransactionIn {
    display: flex;
    align-items: center;
}

.bListTransaction img {
    height: 50px;
    margin-right: 20px;
}

.bListTransaction h4 {
    font-size: 20px;
    padding: 0px;
    margin: 0px;
}

.bListTransaction h3 {
    font-size: 14px;
    padding: 0px;
    margin: 0px !important;
    color: var(--lightBlue);
    font-weight: 400;
}

.bListTransaction b {
    font-size: 14px;
    padding: 0px;
    font-weight: 400;
    margin: 0px;
    color: var(--lightBlue);
}
/***/

.bListIn {
    display: flex;
    align-items: center;
}

.bList img {
    height: 50px;
    margin-right: 20px;
}

.bList h4 {
    font-size: 20px;
    padding: 0px;
    margin: 0px;
}

.bList h3 {
    font-size: 14px;
    padding: 0px;
    margin: 0px !important;
    color: var(--lightBlue);
    font-weight: 400;
}

.bList b {
    font-size: 14px;
    padding: 0px;
    font-weight: 400;
    margin: 0px;
    color: var(--lightBlue);
}

.noConnectedArea {
    padding-top: 100px;
    text-align: center;
}

.noConnectedArea button {
    background-color: var(--yellow);
    padding: 5px 20px;
    margin-bottom: 10px;
}

.transactionItem {
    background-color: var(--darkBlue);
    margin-bottom: 10px;
    padding: 10px;
}

hr {
    color: var(--lightBlue);
}

.transactionsMenu {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
}

.transactionsMenu button {
    height: 32px;
    width: 120px;
}

.transactionStatus {
    display: flex;
    justify-content: space-between;
}

.textError {
    color: rgb(190, 18, 76);
    font-size: 13px;
}

.centerAlertModal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 400px;
}

.centerAlertModal i {
    color: var(--success);
    font-size: 80px;
}

.centerAlertModal p {
    font-size: 18px;
}

.centerAlertModal button {
    padding: 5px 50px;
    background-color: var(--yellow);
}

.modal-display-redes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    background-color: #220e2a;
}

.modal-display-redes p {
    color: orange;
}

.redes-img {
    display: flex;
    justify-content: space-around;
}

.redImages{
    display: flex;
    flex-direction: column;
    color: white;

}

.redes-img img:hover {
    background-color: rgba(170, 65, 202, 0.494);
}


.redes-img img {
    background-color: #00000045;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .redes-img img {
        height: 80px;
    }
}

.redLogoWallet {
    width: 90% !important;
    height: 90% !important;
}

.memory {
    /* padding: 2em; */
    position: relative;
    left: 20px;
    padding-bottom: 0;
}

.mobileMenu {
    background-color: rgba(34, 2, 56, 0.932);
    position: fixed;
    width: 100vw;
    height: 65px;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    /* align-items: center;
    justify-content: space-around; */
}

.mh-100vh {
    min-height: 100vh;
}


.nav-linkMobile {
    background-color: #0a071d2c;
    border-radius: 5px;
    padding: 5px;
    width: 24%;
    height: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.mobileSeparator {
    height: 100px;
}

.mobileMargin {
    margin-top: 0px;
    padding-top: 90px;
}

.adminMenuMobile {
    /* padding: 10px 20px; */
    color: var(--lightBlue);
    user-select: none;
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

.adminMenuMobileLight {
    /* padding: 10px 20px; */
    color: var(--lightBlue);
    user-select: none;
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

/* .adminMenuMobileLight:hover{
    
} */

.sideMenuMobile {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(34, 2, 56, 0.932);
    /* padding: 10px 0; */
    color: white;
}

.nav-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
    margin-top: 0;
    gap: 10px;
    padding-top: 20px;
}

.logo-mobile {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 2px;
}

.container-dashboard {
    height: auto;
    min-height: 106.8vh;
    margin-top: -70px;
}

.container-admin {
    height: auto;
    min-height: 106.8vh;
}

.info {
    cursor: pointer;
}

.info:hover {
    color: rgb(223, 203, 55);
}

@media screen and (max-width: 991px) {
    .padModal {
        padding-bottom: 100px;
    }

    .nav-section-1 img {
        margin-right: 0;
    }

    .mobileMargin {
        margin-top: -120px;
        padding-top: 120px;
    }

    .container-dashboard {
        height: auto;
        min-height: 106.8vh;
    }

    .container-admin {
        height: auto;
        min-height: 106.8vh;
        padding-bottom: 70px;
        margin-top: -120px !important;
    }
}

.meta {
    border: none !important;
    background-color: #13d93b;
    background-color: transparent !important;
}

.b-tittle {
    color: #9d56c3;
    font-size: 22px;
    font-weight: 400;
}

.b-result {
    color: var(--pink);
    font-weight: 400;
    font-size: 16px;
}

select {
    background-color: #290e36 !important;
    border: none !important;
    color: var(--pink) !important;
}

option {
    border: none !important;
}

.coinChanges {
    width: 100%;
    height: 22px;
    color: var(--pink);
    padding-left: 5px;
}

.coinChanges i {
    color: rgb(67, 188, 37);
}

.btnAddBank {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}

.addBank {
    font-size: 12px;
}

.wrapperLoader {
    color: var(--pink);
    font-size: 16px;
}

.linkMetamask {
    color: var(--pink) !important;
    font-size: 14px;
}

.linkMetamask a {
    color: var(--pink) !important;
    font-size: 14px;
}

.swapContainer {
    min-height: 130vh;
}

.kyc {
    min-height: 180px;
}

.linkKyc {
    color: rgb(215, 126, 217);
}

.kycVerification {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: var(--pink);
}

.kycVerification b {
    color: var(--pink);
    font-weight: 300;
    height: 50px;
}

.kycVerification h1 {
    margin: 0px;
    font-weight: 300;
}

.kycVerificationSuccess {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: #16b66cd3;
}

.kycVerificationSuccess b {
    font-weight: 300;
}

.kycVerificationSuccess h1 {
    margin: 0px;
    font-weight: 300;
}

.alertMessage {
    background-color: var(--alertBackground);
    border-radius: 7px;
    margin: 10px 0px;
    padding: 5px 10px;
    border: 2px solid var(--alertText);
    font-size: 14px;
    color: var(--alertText);
}

/* 
    color: rgba(133, 89, 139, 0.88);
    color: #13d93b;*/

.a-link {
    font-size: 14px;
    color: rgb(45, 120, 225);
}

.a-link:hover {
    cursor: pointer;
    color: rgb(110, 163, 238);
}

.send-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}

.send-text b {
    margin: 0px;
    padding: 0px;
    color: var(--pink);
    font-weight: 400;
    font-size: 16px;
}

.send-text h4 {
    color: #ebad00;
    margin: 0px;
    padding: 0px;
    font-size: 28px;
}

.btn-send {
    background-color: var(--yellow);
}

.btn-send :hover {
    background-color: #13d93b;
}

.btn-primary {
    background-color: #ebad00 !important;
    color: #1a0d1f !important;
    width: 100%;
    font-weight: 500;
}

.suspension {
    font-size: 12px;
}

.img-logo-bank {
    width: 50px !important;
    height: 50px !important;
    padding: 5px !important;
}

.btn-mobil-game {
    width: 110px;
    display: flex;
    justify-content: center;
    color: rgb(236, 184, 10);
    cursor: pointer;
}

.container-ico-game {
    width: 60px;
    background-color: rgb(18 44 59 / 23%);
    text-align: center;
    border-radius: 25px;
    padding: 2px;
}

.ico-mobil-game {
    width: 50px;
    border: 1px solid rgb(236, 184, 10);
    border-radius: 20px;
    padding: 1px 3px;
    background-color: #1c0942;
}

.errorText {
    font-size: 14px;
    color: rgb(221, 9, 90);
    text-align: center;
}

.wallet-minified {
    word-break: break-all;
}

.error404 {
    width: 100vw;
    height: 95vh;
    display: flex;
    align-items: center;
    background-color: #1a0d1f;
    color: white;
}

.error404 h1 {
    font-size: 120px;
}

.error404 p {
    font-size: 30px;
    font-weight: 300;
}

.error404 button {
    background-color: var(--yellow);
    border-radius: 40px;
    padding: 10px 30px;

}

.text-small {
    font-size: 14px;
}

.trasferTransactionModal {
    display: flex;
    font-size: 16px;
    font-weight: 300;
}

.trasferTransactionModal h4 {
    font-size: 16px;
    margin: 0px;
    padding-top: 5px;
}

.trasferTransactionModal b {
    font-size: 16px;
    margin: 0px;
    padding-top: 5px;
    font-weight: 400;
}

.text-medium {
    font-size: 18px;
}

.transactionBody {
    min-height: 92vh;
    /* background-color: var(--darkBlue); */
    justify-content: center;
    padding: 20px;
}

.transactionContainer {
    border-radius: 10px;
    background-color: #290e36;
    padding: 20px;
    color: rgb(106, 68, 125);
    position: relative;
    display: flex;
    justify-content: center;
}

.steapSuccess {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--yellow);
}

.steapNoSuccess {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #818181;
}

.hrSuccess {
    border-top: 1px solid var(--yellow);
    min-width: 80px;
    margin: 0px 30px;

}

.hrNoSuccess {
    border-top: 1px solid #818181;
    min-width: 80px;
    margin: 0px 30px;
}

.bChange {
    font-weight: 500;
    color: var(--yellow);
}

.transactionDisplay {
    padding: 15px;
    min-height: 200px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: #00000045;
    margin-bottom: 30px;
    display: grid;
    place-items: center;
    text-align: center;
}

.transactionIn {
    text-align: center;
    max-width: 700px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.backButton {
    background-color: var(--yellow);
    border-radius: 7px;
    position: absolute;
    height: 30px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px;
    top: 20px;
}

.vol {
    margin-right: 10px;
}

.transactionsButtons {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.btnSwap {
    background-color: var(--yellow);
    border-radius: 20px;
    width: 1000px;
}

.btnTransactionSuccess {
    display: flex;
    align-items: center;
    border: 2px solid var(--yellow);
    justify-content: space-between;
    background-color: var(--darkBlue);
    color: var(--yellow);
    border-radius: 25px;
    height: 70px;
    width: 350px;
    margin-bottom: 10px;
}

.btnTransaction {
    margin: 0 auto;
    display: flex;
    align-items: center;
    border: 2px solid hsl(285deg 61% 23%);
    justify-content: space-between;
    background-color: #290e36;
    color: rgba(255, 255, 255, 0.569);
    border-radius: 25px;
    height: 70px;
    width: 350px;
    margin-bottom: 10px;
}

.btnContinue {
    background-color: rgba(122, 80, 148, 0.453);
    color: rgba(28, 14, 47, 0.595);
    border-radius: 20px;
    height: 60px;
    width: 350px;
    font-size: 18px;
    font-weight: 500;
}

.btnContinueSuccess {
    background-color: var(--yellow);
    color: rgba(22, 4, 45, 0.833);
    border-radius: 20px;
    height: 60px;
    width: 350px;
    font-size: 18px;
    font-weight: 500;
}

.btnTransaction:hover {
    outline: 3px solid var(--selected);
    color: var(--selected);
}

.btnInTrans {
    display: flex;
    align-items: center;
}

.transFontSuccess {
    font-size: 30px;
    padding: 0px;
    margin: 0px;
    color: var(--yellow);
}

.transFont {
    font-size: 30px;
    padding: 0px;
    margin: 0px;
    color: #ffffff00;
}

.direccion{
    color: #8c3fa8;
    font-weight: 400;
    font-size: 19px;
}
.linkMap{
    margin-left: 10px;
    color: #8c3fa8;
    font-size: 19px;
}
.linkMap:hover{
    color: #ac62c7;
}

.selectedBank{
    background-color: #1a0d1f;
    border-radius: 15px;
    padding: 10px;
}
.efectivoWrap{
    border-radius: 20px;
    border: 1px solid hsl(285deg 61% 23%);
    padding: 20px;
    background-color: #00000045;
}
.mw-350{
    width: 350px;
}

.text-warning{
    font-size: 1em !important;
    word-break: break-all !important;
}

.p-to{
    position: absolute;
    color: #f5da0c !important;
    top: -15px;
}

.btn-nav-login{
    display: flex;
    justify-content: space-between;
    /* width: 70px; */
    padding: 4px 10px;
    background-color: #3a1e44;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 2px 3px 6px 1px rgb(0 0 0 / 12%);
    color: rgb(236,184,10) !important;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
}

.input-eye{
    background-color: rgba(0, 0, 0, 0.253);
    border: none;
    border-radius: 0 15px 15px 0;
    height: 50px;
    color: aliceblue;
}

.formPassword {
    background-color: rgba(0, 0, 0, 0.253) !important;
    color: aliceblue !important;
  }

  
  .formPassword:focus {
    box-shadow: none !important;
    outline: 2px solid #ccc !important;
  }

@media (max-width: 768px) {
    .modal-display {
        min-width: 90vw;
    }
    .hrNoSuccess {
        min-width: 0px;
    }
    .hrSuccess{
        min-width: 0px;
    }
    .backButton{
        width: 30px;
    }

    .backButton > div{
        display: none;
    }
    .vol{
        margin-right: 0;
    }

    
}

@media(max-width: 650px){
    .title-transaction{
        position: relative;
        max-width: 260px;
        margin: 0 auto;
    }
    .transactionDisplay {
        padding: 20px 0;
    }
    
}

@media (max-width: 576px) {
    .transactionIn{
        max-width: 150px;
        flex-wrap: wrap;
    }
}

@media(max-width: 450px){
    .transactionBody{
        padding: 0px;
    }
    .transactionContainer{
        padding: 20px 0;
        position: unset;
    }

    .backButton{
        width: 80px;
    }

    .backButton > div{
        display: initial;
    }

}


@media(max-width: 380px){

    .title-transaction{
        max-width: 170px;
    }
    .btnTransaction{
        width: 250px;
        margin: 10px auto;
    }
    .btnTransactionSuccess {
        width: 250px;
        margin: 10px auto;
    }
    .btnContinueSuccess{
        width: 250px;
        margin: 10px auto;
    }
    .transactionsButtons{
        margin-bottom: 20px;
    }
    .btnContinue{
        width: 250px;
        margin: 10px auto;
    }
}

.btn-cargar-foto{
    background-color: rgba(0, 255, 34, 0.418);
    padding: 5px 20px;
    border-radius: 5px;
    transition: 0.5s;
}
.btn-cargar-foto:hover{
    background-color: rgba(0, 255, 34, 0.507);
    cursor:pointer;
    transform: scale(1.1);
}
.btn-cargar-foto:active{
    background-color: rgba(14, 152, 206, 0.712);
    transform: scale(1);
}

.file-label {
    display: inline-block;
    padding: 10px 20px;
    /*background-color: #007bff;*/
    background:linear-gradient(311deg,rgba(37,22,125,1) 0%,rgba(111,16,107,1) 82%);
    color: #fff;
    cursor: pointer;
    border-radius: 7px;
  }
  
  .file-label:hover {
    background-color: #0056b3;
  }
  .btn-primary-disabled{
    background-color: #b0b5bb;
    width: 100%;
  }
  .preview-kyc{
    width: 90%;
    border-radius: 3px;
  }