.card-memory{
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
    background-color: #242424;
    border-radius: 8px;
    border: 1px solid #3b3b3b;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-selected-memory {
    transform: rotateY(180deg);
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
}

.card-base-memory{
    backface-visibility: hidden;
    user-select: none; 
}

.card-back-memory{
    transform: rotateY(180deg);
    backface-visibility: hidden;
    user-select: none; 
}

.card-base-memory,.card-back-memory{
    display: grid;
    place-items: center;
    border: none;
}

.card-memory:hover{
    border-color: #646cff;
    background-color: #2a2e32;
}

.img-memory{
    width: 100%;
    max-width: 90px;
    opacity: .7;
}

@media (max-width: 700px){
    .card-memory{
        max-width: 100px;
        max-height: 100px;
    }
    .img-memory{
        min-width: 50px;
    }
}