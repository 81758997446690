@import url('./root.module.css');

.call{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -120px;
    overflow: hidden;
    color: white;
    font-size: 2.1em;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
}

.callLight{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -120px;
    overflow: hidden;
    color: #1c0942;
    font-size: 2.1em;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
}

.number{
    font-size: 2.5em;
    font-weight: 300;
}


.link{
    
}

.linkLight{
    color: #1c0942;
}