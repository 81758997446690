.card{
    padding: 30px 45px;
    border-radius: 20px;
    border: 3px solid #57235d;
    background: rgb(26,10,57);
    background: linear-gradient(311deg, rgba(26,10,57,1) 0%, rgba(43,9,47,1) 54%, rgba(26,10,57,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.cardLight{
    padding: 30px 45px;
    border-radius: 20px;
    border: 3px solid #57235d;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


/* background: rgb(118,197,255); */
/* background: linear-gradient(311deg, rgba(118,197,255,1) 0%, rgba(208,235,255,1) 50%, rgba(175,221,255,1) 100%); */


@media (max-width: 576px) { 
    
    .card, .cardLight{
       position: relative;
       margin: 0 50px;
       height: auto;
    }

 }