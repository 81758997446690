@import url('./root.module.css');

.chart{
    display: flex;
    height: 100vh;
    /* justify-content: space-evenly; */
    align-items: center;
    overflow: hidden;
    background: rgb(26,10,57);
    background: var(--darkgradientPurpleLineal);
    padding: 0 80px;
}

.chartLight{
    display: flex;
    height: 100vh;
    /* justify-content: space-evenly; */
    align-items: center;
    overflow: hidden;
    padding: 0 80px;
    background: rgb(118,197,255);
    background: var(--lightGradientWhite);
}

.details{
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 0 auto;
}

.containerText{
    width: 450px;
    align-self: flex-end;
}

.titleChart{
    font-size: 3vw;
    color: #fff;
}

.titleChartLight{
    font-size: 3vw;
    color: #1c0942;
}

.subTitleChart{
    font-size: 3vw;
    font-weight: 300;
    color: #fff;
}

.subTitleChartLight{
    font-size: 3vw;
    font-weight: 300;
    color: #1c0942;
}


.paragraphChart{
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.417);
}

.paragraphChartLight{
    font-size: 1.2vw;
    color: #1c0942;
}

.picture{
    width: 60%;
    display: grid;
    place-items: center;
}

.pictureLight{
    width: 60%;
    display: grid;
    place-items: center;
    filter: drop-shadow(0 4px 10px rgba(26, 6, 52, 0.872));
}

.imgChart{
    width: 100%;
    max-width: 600px;
}


@media (max-width: 1100px) {

    .details{
        width: 50%;
    }

    .containerText{
        width: 100%;
        align-self: center;
    }
}

@media (max-width: 576px) {
    .chart, .chartLight{
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
        gap: 50px;
        padding: 100px 0;
    }
    .details, .containerText{
        width: auto;
        text-align: center;
    }
    .details{
        width: 90%;
    }

    .titleChart, .titleChartLight{
        font-size: 1.5rem;
    }
    .subTitleChart, .subTitleChartLight{
        font-size: 1.2rem;
    }
    .paragraphChart, .paragraphChartLight{
        font-size: 1rem;
    }
}
