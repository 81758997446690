*{
    margin: 0;
    padding: 0;
}

:root {
    --darkBlue: hsl(284.75, 66%, 17%);
    --darkBlue2: hsl(284.75, 66%, 17%);
    --yellow: rgb(223, 203, 55);
    --yellow2: rgba(187, 146, 12, 0.733);
    --lightBlue: rgba(124, 80, 175, 0.685);
    --light: rgba(124, 80, 175, 0.685);
    --lightBlue2: rgba(175, 221, 255, 1);
    --white: aliceblue;
    --lightModa: #ecf7ff;
    --lightNav: #ecf6ff;
    --success: rgb(40, 156, 66);
    --pink: rgb(220, 173, 235);
    --darkGradient: linear-gradient(31deg, rgba(26, 10, 57, 1) 0%, rgba(43, 9, 47, 1) 54%, rgba(26, 10, 57, 1) 100%);
    --lightGradientWhite: linear-gradient(311deg, rgb(166 218 255) 0%, rgba(208, 235, 255, 1) 50%, rgb(231 245 255) 100%);
    --alertText: rgb(110, 4, 100);
    --alertBackground: rgba(194, 67, 189, 0.878);
    --selected: var(--yellow);
}

.transactionsContainer{
    display: flex;
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
}

.bank-container{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.bank-item{
    width: 50%;
    height: 70px;
    background-color: #290e36;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 10px;
    /* gap: 5px; */
    border: 2px solid hsl(285deg 61% 23%);
    cursor: pointer;
    font-size: 16px;
}

.bank-item:hover{
    border: 2px solid var(--yellow);
    color: var(--yellow);
}

.bank-item img{
    width: 100%;
    max-width: 50px;
}

.bank-item p{
    margin-bottom: 0;
    margin-right: 15px;
}

.method-item{
    width: 100%;
    height: 70px;
    background-color: #290e36;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 5px;
    border: 2px solid hsl(285deg 61% 23%);
    cursor: pointer;
}

.method-item:hover{
    border: 2px solid var(--yellow);
}

.method-item img{
    width: 100%;
    max-width: 50px;
    min-width: 50px;
}

.method-item-center{
    display: flex;
    margin: 0 auto;
    max-width: 200px;
    align-items: center;
    gap: 5px;
}

.item-method{
    width: 150px;
}

.text-cash{
    font-size: 16px;
    margin-bottom: 0;
}

.container-transaction3{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

/* transaction 3 */
.transaction-text{
    align-self: flex-start;
}

.transaction-amount{
    color: rgb(223 203 55);
    font-size: 1em;
    margin-top: -20px;
    margin-bottom: 0px;
    word-break: break-all;
    /* max-width: 350px; */
}

.check-confirm{
    display: flex;
    gap: 5px;
}

.form-check-input[type=checkbox]{
    background-color: #3c1c4b;
}

.transaction-input{
    display: flex;
    margin: 0 auto;
    max-width: 350px;
    border-radius: 15px;
    padding: 10px;
    background-color: hsl(284deg 66% 17%);
    border: 2px solid hsl(285deg 61% 23%);
    font-size: 16px;
    color: rgb(223 203 55);
    margin-bottom: 10px;
}

.red-container{
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 10px;
    width: 350px;
    margin-bottom: 20px;
    justify-content: center;
}


.red-item{
    background-color: hsl(284deg 66% 17%);
    border-radius: 25px;
    min-width: 95px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.red-item:hover{
    border: 2px solid rgb(223 203 55);
   
}

.red-item p{
    margin-bottom: 0px;
}

.red-item-active{
    border: 2px solid rgb(223 203 55);
    position: relative;
}

.red-check{
    position: absolute;
    left: 85px;
    font-size: 25px;
    color: rgb(223 203 55) !important;
}

.red-item .bi{
    color: transparent;
}

@media (max-width: 576px) {
    
}