.modal-content-memory{
    background-color: #0a071d;
    background-image: 
        radial-gradient(at 47% 33%, hsl(284.75, 66%, 17%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(284.75, 66%, 17%) 0, transparent 55%);
    width: 100%;
    min-height: 100vh;
    bottom: 0;
    z-index: 1;
    display: grid;
    place-items: center;
    color: white;
}

.modal-memory{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 650px;
    min-height: 700px;
    height: auto;
    background-color: #150428;
    border-radius: 8px;
    padding: 0px 25px 10px 25px;
    border: 1px solid #3b3b3b;
}

.modal-header-memory{
    text-align: left;
    padding: 15px 0;
    font-size: 22px;
}

.hr-memory{
    background-color: #2a2a2a;
    margin-bottom: 10px;
    height: 1px;
}

.modal-score-memory{
    height: 55px;
    background-color: #24242478;
    border-radius: 8px;
    display: flex;
    border-top: 5px solid #2a2e32;
}

.score-movie-memory{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5px;
}

.score-points-memory{
    font-size: 24px;
}

.modal-body-memory{
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0 0 0;
    margin: 0 70px;
    justify-content: center;
    align-items: center;
    gap: 15px;
}


.modal-footer-memory{
    margin-top: 10px;
    text-align: center;
    padding: 30px 0;
}

.modal-footer-memory button{
    border: 1px solid #2a2a2a;
    margin: 0 20px;
}

.total-matches-memory{
    font-size: 18px;
}

@media (max-width: 700px){
    .modal-content-memory{
        position: relative;
    }
    .modal-memory{
        max-width: 100%;
        min-height: auto;
        padding-bottom: 50px;
    }
    .modal-header-memory {
        padding: 15px 20px;
    }
    .modal-score-memory {
        padding: 0 20px;
    }
    .modal-body-memory{
        margin: 0;
        gap: 1px;
    }
}